<template>
  <!-- 作品详情配置列表 -->
  <body-layout v-if="type == 'GLOBAL'" :style="backCss">
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        v-has="'system:productDetail:delete'"
        icon="delete"
        >删除</a-button
      >
      <a-button
        type="default"
        class="mr-3"
        @click="batchCopy"
        icon="copy"
        v-if="selectedRowKeys.length"
        v-has="'system:productDetail:edit'"
        >创建副本</a-button
      >
      <!-- <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length >= 2"
        icon="cloud"
        v-has="'system:productDetail:edit'"
        >同步</a-button
      > -->
      <!--       <a-button class="mr-3" icon="download" type="primary" @click="exportExcel">导出</a-button>
      <a-button
        class="mr-3"
        type="primary"
        @click="openAddForm"
        icon="import"
        v-has="'system:productDetail:add'"
      >导入</a-button> -->
      <a-button type="primary" icon="plus" @click="openAddForm" v-has="'system:productDetail:add'">新建</a-button>
    </div>
    <div>
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        ref="table"
        size="middle"
        id="out-table"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
        @change="handleTableChange"
      >
        <span slot="type" slot-scope="text, record">{{ record.cardList[0] | typeFilter }}</span>
        <span slot="canChapter" slot-scope="text, record">{{ record.canChapter ? '是' : '否' }}</span>
        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">{{ one.departName }}</a-tag>
        </span>
        <span slot="valueRequired" slot-scope="text, record">
          {{ record.cardList[0].dataList[0].valueRequired == 'YES' ? '是' : '否' }}
        </span>
        <span slot="positionNames" slot-scope="text, record">
          <a-tag v-for="one in record.positions" :key="one.positionId"
            >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
          >
        </span>
        <span slot="stages" slot-scope="text, record">
          <a-tag v-for="one in record.stages" :key="one.stageId"
            >{{ one.stageName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
          >
        </span>
      </a-table>
    </div>
    <product-modal
      :type="type"
      :open="drawerShow"
      :domain="domain"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    />
    <sync-modal ref="syncModal" :selectionRows='selectionRows' />
  </body-layout>
  <div v-else>
    <div class="actions flex jc-end mb-4" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        v-has="'system:productDetail:delete'"
        icon="delete"
        >删除</a-button
      >
      <a-button
        type="default"
        class="mr-3"
        @click="batchCopy"
        icon="copy"
        v-if="selectedRowKeys.length"
        v-has="'system:productDetail:edit'"
        >创建副本</a-button
      >
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length >= 2"
        icon="cloud"
        v-has="'system:productDetail:edit'"
        >同步</a-button
      >
      <a-button type="primary" icon="plus" @click="openAddForm" v-has="'system:productDetail:add'">新建</a-button>
    </div>
    <div>
      <a-table
        ref="table"
        id="out-table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
        @change="handleTableChange"
      >
        <span slot="type" slot-scope="text, record">{{ record.cardList[0] | typeFilter }}</span>
        <span slot="canChapter" slot-scope="text, record">{{ record.canChapter ? '是' : '否' }}</span>
        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">{{ one.departName }}</a-tag>
        </span>
        <span slot="valueRequired" slot-scope="text, record">
          {{ record.cardList[0].dataList[0].valueRequired == 'YES' ? '是' : '否' }}
        </span>
        <span slot="positionNames" slot-scope="text, record">
          <a-tag v-for="one in record.positions" :key="one.positionId"
            >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
          >
        </span>
      </a-table>
    </div>
    <product-modal
      :type="type"
      :relationId="relationId"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
      :domain="domain"
    />
    <sync-modal ref="syncModal" :selectionRows='selectionRows' />
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import productModal from './modules/product/product-modal.vue'
import syncModal from '@/components/sync-modal'
import OMS from '@/excel/excelOut.js'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  mixins: [JeecgListMixin],
  components: {
    productModal,
    syncModal
  },
  data() {
    return {
      filters: {
        type: this.type,
        relationId: this.relationId
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 50
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 105
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 105
        },
        {
          title: '适用到章节',
          scopedSlots: { customRender: 'canChapter' },
          dataIndex: 'canChapter',
          width: 105
        },
        {
          title: '是否必填',
          scopedSlots: { customRender: 'valueRequired' },
          dataIndex: 'valueRequired',
          width: 105
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 280
        },
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions',
          width: 160
        },
        {
          title: '适用阶段',
          scopedSlots: { customRender: 'stages' },
          dataIndex: 'stages',
          width: 160
        }
      ],
      domain: 'PRODUCT_DETAIL',
      dataSource: [],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: {
          path: '/productDetail/page'
        },
        copyBatch: 'productDetail/copy',
        delete: '/fonts/delete',
        deleteBatch: '/productDetail/',
        syncPath: 'productDetail/sync'
      },
      color: ''
    }
  },
  computed: {
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': backColor
      }
    }
  },
  filters: {
    typeFilter(data) {
      const typeMap = {
        DYNAMIC_FORM: `动态表单${
          data.dataList[0].type == 'INPUT'
            ? '-文本录入'
            : data.dataList[0].type == 'SELECT'
            ? '-下拉选项'
            : data.dataList[0].type == 'TAG'
            ? '-标签'
            : ''
        }`,
        INPUT: '单行文本',
        INPUT_NUMBER: '数字输入框',
        TEXTAREA: '多行文本',
        RADIO: '单项选择',
        SELECT: '下拉选择',
        TAG: '标签'
      }
      return typeMap[data.type]
    }
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 100)
  },
  methods: {
    // exportExcel() {
    //   /* out-table关联导出的dom节点  */
    //   var wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
    //   /* get binary string as output */
    //   var wbout = XLSX.write(wb, {
    //     bookType: 'xlsx',
    //     bookSST: true,
    //     type: 'array'
    //   })
    //   var filename =  '作品详情.xlsx'
    //   try {
    //     FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename)
    //     this.$message.success('导出成功')
    //   } catch (e) {
    //     this.$message.error('导出失败')
    //     if (typeof console !== 'undefined') console.log(e, wbout)
    //   }
    //   return wbout
    // }
    exportExcel() {
      let that = this
      OMS.downLoadXlsx({
        dom: 'out-table', //获取表格dom，然后拿出数据，所以我需要知道解析哪一个表格
        name: '作品详情', //excel名字
        ColumnWdth: that.columns.map(item => {
          return item.width
        }) //每一列的宽度，需要直接指定，接受数字
      })
    }
  },
  props: {
    type: {
      type: String,
      default: () => 'GLOBAL'
    },
    relationId: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .border-bottom {
  border: none;
}
/deep/ .ant-table-row:hover {
  .ant-table-row-cell-break-word {
    background-color: var(--back-color) !important;
  }
}
</style>
